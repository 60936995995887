import { useEffect, useRef } from "react";
import { useParams } from "react-router";
import SummaryTab from "../../components/summary-tab";
import './style.scss';

export default function SummaryPage() {
    const { id: _id } = useParams<{ id: string }>();
    const id = parseInt(_id);

    const pageHeader = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        pageHeader.current?.focus();
    }, [])

    return <main className="summary-page">
        <header>
            <div className="summary-top-gfx"></div>
            <h1 ref={pageHeader} className="heading-1">Podsumowanie</h1>
        </header>

        <SummaryTab id={id} />
    </main>

}
