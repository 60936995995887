import { useQuery } from "react-query";
import { USER_ACTION, useUserModuleDispatch, useUserModuleState } from ".."
import { useGetUserRequest } from "../../network";
import useLogout from "./use-logout";

export default function useUser() {

    const user = useUserModuleState();
    const dispatch = useUserModuleDispatch();
    const logout = useLogout();

    const getUser = useGetUserRequest();

    const { isFetching, error } = useQuery(['get-user', user.id], getUser, {
        staleTime: Infinity,
        onSuccess(data) {
            dispatch({
                type: USER_ACTION.FETCH_SUCCESS,
                payload: {
                    ...data
                }
            })
        },
        onError() {
            logout();
        }
    })

    return {
        isLoading: isFetching,
        error,
        user
    }
}
