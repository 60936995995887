import React, { useEffect } from "react";
import ErrorInfo from "../../components/error";
import Loader from "../../components/loader";
import useNewGameRun from "../../modules/game-run/hooks/use-new-game-run";
import useUser from "../../modules/user/hooks/use-user";

type Props = {}

const GameIdHandler: React.FC<Props> = ({ children }) => {

    const { user, isLoading, error } = useUser();

    const hasActiveRun = user.activeRun;

    return <>
        {isLoading && <Loader />}
        {!isLoading && <>
            {error && <ErrorInfo />}
            {hasActiveRun && children}
            {!hasActiveRun && <NewRunHandler>{children}</NewRunHandler>}
        </>}
    </>
}

export default GameIdHandler;


const NewRunHandler: React.FC<{}> = ({ children }) => {

    const { newGameRun, error, isLoading } = useNewGameRun();

    useEffect(() => {
        newGameRun();
    }, [newGameRun])

    return <>
        {error && <ErrorInfo />}
        {isLoading && <Loader />}
        {!error && !isLoading && children}
    </>
}
