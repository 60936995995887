import { useCallback } from "react";
import useGame from "./use-game";

export default function useGetActionStepSumary() {
    const { game } = useGame();

    return useCallback((stepId: string, answer?: number) => {
        const partial = game.summary.partials.find(p => p.key === stepId);

        return {
            text: partial?.versions?.[`${answer}`].text || '',
            audio: partial?.versions?.[`${answer}`].audio || {}
        }
    }, [game]);
}
