import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useRef } from "react";
import { Context } from "react";
import { CONFIG } from "../../config";

type Props = {
    interceptor: (config: AxiosRequestConfig) => AxiosRequestConfig
}

type Output = [
    React.FC<Props>,
    () => AxiosInstance
]

export default function createAxiosProxider(): Output {

    const axiosInstance = Axios.create({
        baseURL: CONFIG.BACKENT_URL,
        withCredentials: true
    });

    const axiosContext: Context<{
        axios: AxiosInstance
    }> = React.createContext({ axios: axiosInstance });

    // axiosInstance.interceptors.request.use(config => {
    //     return new Promise(r => setTimeout(() => r(config), 1000))
    // })

    const NetworkProvider: React.FC<Props> = ({ children, interceptor: axiosInterceptor }) => {
        const accessToken = useRef({ value: '' });

        useEffect(() => {
            const id = axiosInstance.interceptors.request.use(axiosInterceptor);

            return () => {
                axiosInstance.interceptors.request.eject(id);
            }
        }, [axiosInterceptor]);

        const setAccessToken = useCallback((value: string) => {
            accessToken.current.value = value;
        }, [])

        const value = {
            axios: axiosInstance,
            setAccessToken
        }

        return <axiosContext.Provider value={value}>
            {children}
        </axiosContext.Provider>
    }

    function useAxios() {
        return React.useContext(axiosContext).axios;
    }

    return [
        NetworkProvider,
        useAxios
    ]
}
