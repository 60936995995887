import { Fragment, useEffect, useRef } from "react";
import { GameStep } from "../../../modules/game";
import { ClassResult } from "../../../modules/teacher/hooks/use-class-raport";

type Props = {
    steps: GameStep[],
    results: ClassResult[]
}

const RaportTree: React.FC<Props> = ({ steps, results }) => {
    const header = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        header.current?.focus();
    }, [])
    return <div className="raport-tree">
        <h1 ref={header} className="heading-1">Raport grupy</h1>
        {

            results && steps && steps.map(s => {

                const stepResult = results.find(sr => sr.id === s.id);

                return <Fragment key={s.id}>
                    <div className="step">
                        <h2 className="heading-decor">
                            {s.inGameTime}
                        </h2>
                        <div className="step-info">
                            <p dangerouslySetInnerHTML={{ __html: s.text }}>
                            </p>
                            {
                                stepResult && <div className="step-result">
                                    <div className="stat">
                                        <div className="text">
                                            Wszyskich{'\u00A0'}odpowiedzi:
                                        </div>
                                        <div className="value">
                                            {stepResult.globalVotes}
                                        </div>
                                    </div>
                                    <div className="stat">
                                        <div className="text">
                                            Odpowiedzi{'\u00A0'}w{'\u00A0'}tej{'\u00A0'}grupie:
                                        </div>
                                        <div className="value">
                                            {stepResult.classVotes}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {s.actions && <>
                            <h3>Akcje do wyboru:</h3>
                            <div className="actions">

                                {s.actions.map((a, index) => {
                                    const actionResult = stepResult?.actions.find(ar => ar.value === a.value);

                                    return <div className='action' key={index}>
                                        <p>
                                            {a.text}
                                        </p>
                                        {actionResult && stepResult && <div className="action-result">
                                            <div className="stat">
                                                <div className="text">Wszyskich{'\u00A0'}odpowiedzi:</div>
                                                <div className="value">{actionResult.globalVotes}</div>
                                                <div className="proc">{(actionResult.globalVotes / stepResult.globalVotes * 100).toFixed(2)}%</div>
                                            </div>
                                            <div className="stat">
                                                <div className="text">Odpowiedzi{'\u00A0'}w{'\u00A0'}tej{'\u00A0'}grupie:</div>
                                                <div className="value">{actionResult.classVotes}</div>
                                                <div className="proc">{(actionResult.classVotes / stepResult.classVotes * 100).toFixed(2)}%</div>
                                            </div>
                                        </div>}
                                    </div>
                                })}
                            </div>
                        </>
                        }
                    </div>
                </Fragment>
            })
        }
    </div>
}

export default RaportTree;


