import { useMsal } from "@azure/msal-react"
import { useCallback } from "react"
import { CONFIG } from "../../config"
import useLogout from "../../modules/user/hooks/use-logout"

type Props = {

}

const LogoutButton: React.FC<Props> = () => {
    return <>
        {(CONFIG.HOSTING_TYPE === 'azure') && <AzureLogoutButton />}
        {(CONFIG.HOSTING_TYPE === 'classic') && <ClassicLogoutButton />}
    </>
}


const AzureLogoutButton: React.FC<Props> = () => {

    const {instance} = useMsal();
    const logout = useCallback(() => {
        instance.logout();
    }, [instance]);

    return <button className="button" onClick={logout}>Wyloguj</button>
}


const ClassicLogoutButton: React.FC<Props> = () => {

    const logout = useLogout()

    return <button className="button" onClick={logout}>Wyloguj</button>
}

export default LogoutButton;
