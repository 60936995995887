import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { GameStep, StepAction } from "../../modules/game";
import StepVideo from "../video-step";

import './style.scss';

type Props = {
    step: GameStep,
    onStepComplete: (action: StepAction) => void,
    disableButton?: boolean
}

const letters = ['A', 'B', 'C'];

const StepDefault: React.FC<Props> = ({ step, onStepComplete, disableButton }) => {

    const { register, watch, handleSubmit, setValue } = useForm();
    const headerRef = useRef<HTMLHeadingElement>(null);

    const actions = useMemo(() => (step.actions || []), [step]);

    const actionIndex = watch('actionIndex', null);
    const isNextAvaiable = (actions.length === 1 || actionIndex != null) && !disableButton;

    const onSubmit = useCallback((data: any) => {

        if (actions.length === 1) {
            onStepComplete(actions[0]);
        }

        const action = actions[data.actionIndex];
        if (action) {
            onStepComplete(action);
        }
    }, [actions, onStepComplete]);

    useEffect(() => {
        setValue('actionIndex', null);
    }, [step, setValue])

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.focus();
        }
    }, [step])


    const imgSrc = useMemo(() => {
        return `url(${step.image}?random=${Math.random()})`
    }, [step])

    return <div className="step-default">
        <header>
            <h1 className="step-heading" ref={headerRef} tabIndex={-1}>{step.inGameTime}</h1>
        </header>
        <div className="step-text" tabIndex={-1}>
            <p dangerouslySetInnerHTML={{ __html: step.text }}></p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="task-actions" role='group' aria-label="Możliwe akcje">
                {
                    actions.length > 1 && actions.map((a, index) => {
                        return <div className="action" key={`${step.id}${index}`}>
                            <input type="radio" name="actionIndex" value={index} ref={register} id={`action-${index}`} />
                            <label className="action-radio" htmlFor={`action-${index}`}>
                                <span aria-hidden="true">{letters[index]}</span>
                                <p>{a.text}</p>
                            </label>
                        </div>
                    })
                }
            </div>
            <button className="button" disabled={!isNextAvaiable} aria-disabled={!isNextAvaiable}>Dalej</button>
        </form>
    </div>
}

export default StepDefault;
