import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGameStep from "../../modules/game/hooks/use-game-step";
import StepDefault from '../../components/step-default';
import { StepAction, STEP_LAYOUT } from "../../modules/game";
import StepRules from "../../components/step-rules";
import useUser from "../../modules/user/hooks/use-user";
import useUpdateActiveStep from "../../modules/user/hooks/use-update-active-step";
import useUserMakeChoice from "../../modules/user/hooks/use-user-make-choice";
import useGetActionStepSumary from "../../modules/game/hooks/use-get-element-sumary";

import './style.scss';
import Loader from "../../components/loader";
import Popup from "./popup";
import StepTemplate from "../../components/step-template";
import ErrorInfo from "../../components/error";
import ScrollToMe from "../../utils/scroll-to-me";

type PopupData = {
    action: StepAction,
    nextStep: string,
    text: string,
    audio?: {
        ogg?: string,
        m4a?: string
    }
}

export default function GameScreen() {

    // Informacje o userze
    const { user, isLoading: userLoading, error: userError } = useUser();
    const initStep = user.activeStep;

    // ID Aktualnie wyświetlanego kroku
    const [stepId, setStepId] = useState<string | null>(user.activeStep);

    // Dane aktulanie wyświetlanego kroku
    const { step, isLoading: stepLoading, error: gameRunError } = useGameStep(stepId);

    // Dane do popupa
    const [popupData, setPopupData] = useState<null | PopupData>(null);

    // Obsługa podjętej przez usera decyzji
    const {userMakeChoice} = useUserMakeChoice(step);

    // Gdy ładujemy dane usera lub aktualnego kroku to pokazujemy loader
    const isLoading = userLoading || stepLoading;

    // Funkcja do pobierania informacji do popupa po dokonaniu wyboru.
    const getPopupSummaryData = useGetActionStepSumary();

    const showPopup = popupData?.action?.text || popupData?.action?.points?.catA || popupData?.action?.points?.catP || popupData?.action?.points?.catZ;

    // Na początku pobieramy stepID z danych usera
    useEffect(() => {
        if (!stepId && initStep) {
            setStepId(initStep);
        }
    }, [stepId, initStep, setStepId]);

    const error = userError || gameRunError;


    // Callback po zakończeniu całego stepu
    const onStepComplete = useCallback(async (action: StepAction) => {
        const nextStep = await userMakeChoice(action);

        if (nextStep && stepId && action?.value && isPopupNeeded(action)) {
            const summary = getPopupSummaryData(stepId, action.value);
            setPopupData({
                nextStep,
                action,
                ...summary
            });
        } else {
            setStepId(nextStep);
        }
    }, [userMakeChoice, setStepId, setPopupData, getPopupSummaryData, stepId]);

    const onPopupOk = useCallback(() => {
        if (popupData) {
            setStepId(popupData.nextStep);
            setPopupData(null);
        }
    }, [setStepId, popupData, setPopupData]);

    const showStep = !isLoading && !error;

    useUpdateActiveStep(user.activeStep);

    const StepComponent = useMemo(() => {

        if (!step) {
            return <p>Nie ma nastepnego kroku...</p>
        }

        switch (step?.layout) {
            case STEP_LAYOUT.RULES:
                return <StepRules onStepComplete={onStepComplete} step={step} />;

            default:
                return <StepDefault onStepComplete={onStepComplete} step={step} />;
        }
    }, [step, onStepComplete]);


    return <div>
        {error && <ErrorInfo />}
        {isLoading && <Loader />}

        {showStep && step && <div className="game-step">
            <ScrollToMe trigger={stepId} behavior="smooth" />
            <StepTemplate step={step}>
                {/* {isPostStepLoading && <Loader />}
                {!isPostStepLoading && StepComponent} */}
                {StepComponent}
            </StepTemplate>
        </div>}

        {showPopup && <Popup action={popupData?.action} text={popupData?.text} audio={popupData?.audio} onPopupOk={onPopupOk} />}
    </div>
}


/**
 * Popup wyswietlamy tylko wtedy gdy
 *
 * @param action
 * @returns
 */
function isPopupNeeded(action: StepAction) {
    return Boolean(
        action.points?.catA || action.points?.catP || action.points?.catZ
    )
}


