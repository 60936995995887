import { useQuery } from "react-query";
import { GAME_RUN_ACTION, useGameRunModuleDispatch, useGameRunModuleState } from "..";
import { useGetGameRunRequest } from "../../network";

export default function useGameActiveRun() {

    const gameRun = useGameRunModuleState();

    const dispatch = useGameRunModuleDispatch();

    const getGameRun = useGetGameRunRequest()

    const { isFetching, error } = useQuery(['get-run', gameRun.id], () => {
        if (!gameRun.id) return Promise.resolve({
            choices: [],
            rules: []
        });
        return getGameRun(gameRun.id)
    }, {
        staleTime: Infinity,
        onSuccess: (data: any) => {
            dispatch({
                type: GAME_RUN_ACTION.FETCH_SUCCESS,
                payload: {
                    ...data
                }
            })
        }
    })

    return {
        isLoading: isFetching || gameRun.isLoading,
        gameRun,
        error
    }

}
