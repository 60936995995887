import React, { useCallback } from "react";
import DemoLoginForm from "../../components/demo-login-form";
import { NetworkProvider } from "../network";
import useIsLogged from "../user/hooks/use-is-logged"

type Props = {

}

const HostingContainer: React.FC<Props> = ({children}) => {

    const isLogged = useIsLogged();

    const interceptor = useCallback((config: any) => {
        return config;
    }, [])

    return <NetworkProvider interceptor={interceptor}>
        {isLogged && <>{children}</>}
        {!isLogged && <DemoLoginForm />}
    </NetworkProvider>
}

export default HostingContainer;
