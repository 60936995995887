import React  from "react";
import { useParams } from "react-router";
import ErrorInfo from "../../../components/error";
import Loader from "../../../components/loader";
import useClassRaport from "../../../modules/teacher/hooks/use-class-raport";
import RaportTree from "./raport-tree";

import './style.scss';

type Props = {}

const ClassRaportPage: React.FC<Props> = () => {
    const { id } = useParams<{ id: string }>();

    const { steps, results, isLoading, error } = useClassRaport(id);

    return <main className="class-raport-page">
        <div className="summary-top-gfx"></div>
        <div className="content">
            {isLoading && <Loader />}
            {!isLoading && <>

            </>}
            {/* <ErrorInfo /> */}
            {steps && results && <>
                <RaportTree steps={steps} results={results} />
            </>}
            {error && <ErrorInfo />}
        </div>
    </main>
}

export default ClassRaportPage;

