import React from 'react';
import GameIdHandler from './game-id-wrapper';
import GameScreen from './game-screen';
import './style.scss';

export default function GamePage() {

    return <main className="game-page">
        <GameIdHandler>
            <GameScreen />
        </GameIdHandler>
    </main>
}


