import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useGameRun from "../../modules/game-run/hooks/use-game-run";
import useSetRunID from "../../modules/game-run/hooks/use-set-run-id";
import useGame from "../../modules/game/hooks/use-game";
import { getHomeRoute } from "../../routes/routes";
import PointsElement from "./points-element";
import SummaryElement from "./summary-phrase";

import './style.scss';
import Loader from "../loader";
import ErrorInfo from "../error";

type Props = {
    id: number
}

const SummaryTab: React.FC<Props> = ({ id }) => {
    const setRunID = useSetRunID();
    const { gameRun, isLoading: runLoading, error: runError } = useGameRun(id);
    const { game, isLoading: gameLoading, error: gameError } = useGame();

    const isLoading = runLoading || gameLoading;
    const error = runError || gameError;

    const { order } = game.summary;

    useEffect(() => {
        setRunID(id);
    }, [id, setRunID]);

    return <div className="summary-tab">
        {!isLoading && !error && gameRun && <div>
            <section>
                <h2 className="heading-decor">Twoje punkty umiejętności:</h2>
                <PointsElement gameRun={gameRun} game={game} />
            </section>

            <section>
                <h2 className="heading-decor">Twoje zasady</h2>
                <div className="rules">
                    <ul>
                        {gameRun.rules.map((r: any, index: any) => {
                            return <li key={index}>
                                <div className="wrapper">
                                    <h3>Zasada {index + 1}</h3>
                                    <p>{r}</p>
                                </div>
                            </li>
                        })}
                    </ul>
                </div>
            </section>

            <section>
                <h2 className="heading-decor">Twoje wybory</h2>
                <div className="choises">
                    <ul>
                        {order.map(sElemId => {
                            return <li key={sElemId}>
                                <SummaryElement gameRun={gameRun} elementId={sElemId} />
                            </li>
                        })}
                    </ul>
                </div>

            </section>

            <div className="actions">
                <Link className="button" to={getHomeRoute()}>Przejdź do strony głównej</Link>
            </div>
        </div>}

        {error && !isLoading && <ErrorInfo />}
        {isLoading && <Loader />}
    </div>
}



export default SummaryTab;
