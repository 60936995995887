import './style.scss';
import useHighContrastMode from '../../modules/main/hooks/use-set-high-contrast-mode';
import useFontSizeMode from '../../modules/main/hooks/use-font-size-mode';

type Props = {

}

const AccessibilityBar: React.FC<Props> = () => {

    const {setMode} = useHighContrastMode();

    const {setFontSize} = useFontSizeMode();

    return <aside className="accessibility-bar">
        <div className="bar-container">
            <div className="menus-container">
                <div className="contast-menu accessibility-menu">

                    <div id="contast-menu-label" className="accessibility-menu-label">Kontrast:</div>
                    <div className="buttons" role="group" aria-labelledby="contast-menu-label">
                        <button onClick={() => setMode(false)} className="contrast-button normal-mode" aria-label="Wyłącz tryb wysokiego kontrastu">A</button>
                        <button onClick={() => setMode(true)} className=" contrast-button high-contrast" aria-label="Włącz tryb wysokiego kontrastu">A</button>
                        {/* <input className="contrast-button" type="image" src={normalModeButton} alt="Wyłącz tryb wysokiego kontrastu" />
                        <input className="contrast-button" type="image" src={contrastModeButton} alt="Włącz tryb wysokiego kontrastu" /> */}
                    </div>
                </div>
                <div className="font-size-menu accessibility-menu">
                    <div id="font-size-menu-label" className="accessibility-menu-label">Czcionka:</div>
                    <div className="buttons" role="group" aria-labelledby="font-size-menu-label">
                        <button onClick={() => {setFontSize(1)}} className="font-button font-100" aria-label="Normalna wielkość tekstu">A<sup></sup></button>
                        <button onClick={() => {setFontSize(1.5)}} className="font-button font-150" aria-label="Wielkość tekstu 150%">A<sup>+</sup></button>
                        <button onClick={() => {setFontSize(2)}} className="font-button font-200" aria-label="Wielkość tekstu 200%">A<sup>++</sup></button>
                    </div>
                </div>
            </div>
        </div>
    </aside>
}

export default AccessibilityBar;
