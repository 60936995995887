import { useMemo } from "react";
import useClasses from "./use-classes";

export default function useClassInfo(id: string) {
    const {classesList, isLoading, error} = useClasses();

    const info = useMemo(() => {
        return classesList.find(c => c.id === id) || null;
    }, [classesList, id]);

    return {
        info,
        isLoading,
        error
    }
}
