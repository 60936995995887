import { useCallback } from "react";
import { USER_ACTION, useUserModuleDispatch } from "..";

export default function useMoveToStep() {

    const dispatch = useUserModuleDispatch();

    return useCallback((id: string) => {
        dispatch({
            type: USER_ACTION.SET_ACTIVE_STEP,
            payload: {
                id
            }
        })
    }, [dispatch])
}
