import createStore from "../../utils/react-create-store";
import reducer from "./reducer";

const CONFIG_MODULE_LS_KEY = 'page_config'

export type MainModuleState = {
    isLoading: boolean,
    isHighContrastMode: boolean,
    fontSize: number
}

const initMainModuleState: MainModuleState = {
    isLoading: false,
    isHighContrastMode: false,
    fontSize: 1
}

export enum MAIN_MODULE_ACTION {
    FETCH_CONFIG,
    FETCH_CONFIG_SUCCESS,
    SET_HIGH_CONTRAST_MODE,
    SET_FONT_SIZE
}

export type MainModuleAction = {
    type: MAIN_MODULE_ACTION,
    payload?: any
}

const [
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
] = createStore(reducer, initMainModuleState, CONFIG_MODULE_LS_KEY)


export {
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
}
