import { useMemo } from "react";
import { GameStep } from "..";
import useGameActiveRun from "../../game-run/hooks/use-game-active-run";
import useGame from "./use-game";

export default function useGameStep(id: string | null) {

    const { game, isLoading: isGameLoading, error: gameError } = useGame();
    const {
        isLoading: isUserLoading,
        error: gameRunError,
        gameRun
    } = useGameActiveRun();

    const isLoading = isGameLoading || isUserLoading

    const step = useMemo(() => {
        const { steps, startStep } = game;

        if (isUserLoading || gameRunError || isGameLoading || gameError) {
            return null;
        }

        if (steps.length === 0 || !startStep) {
            return null;
        }

        return findFirstCorrectStep(steps, startStep, id, gameRun.answers.choices);
    }, [game, id, isUserLoading, gameRunError, isGameLoading, gameError, gameRun]);

    return {
        isLoading,
        error: gameError || gameRunError,
        step
    }
}


function findFirstCorrectStep(steps: GameStep[], startStep: string, id: string | null, choices: { key: string, value: number }[]) {
    let stepId: string | null = id || startStep;
    let step: GameStep | null;

    while (!!(step = findStep(steps, stepId))) {
        if (!step) {
            return null;
        }

        if (step.condition) {
            const userChoice = findUserChoice(choices, step.condition.key);
            const showThisStep = step.condition.reverse ? userChoice?.value !== step.condition.value : userChoice?.value === step.condition.value

            if (!showThisStep) {
                stepId = step.nextStepId || null;
                continue;
            }
        }

        return step;
    }

    return null;
}

function findStep(steps: GameStep[], id: string | null): GameStep | null {
    return steps.find(s => s.id === id) || null
}

function findUserChoice(choices: { key: string, value: number }[], key: string) {
    return choices.find(c => c.key === key);
}
