export enum STEP_LAYOUT {
    DEFAULT = 'default',
    RULES = 'rules'
}

export type StepAction = {
    value?: number | null,
    text: string,
    nextStep?: string,
    points?: {
        catA?: number,
        catP?: number,
        catZ?: number
    }
}

export type GameStep = {
    id: string,
    text: string,
    actions?: StepAction[],
    layout?: STEP_LAYOUT,
    condition?: {
        key: string,
        value: number,
        reverse?: boolean
    },
    nextStepId?: string,
    image: string,
    video?: string,
    inGameTime: string
}

export type SummaryPartial = {
    key: string,
    versions: {
        [key: string]: {
            text: string,
            audio?: {
                ogg?: string,
                m4a?: string
            }
        }
    }
}

export type GameModuleState = {
    isLoading: boolean,
    startStep?: string,
    steps: GameStep[],
    summary: {
        order: string[],
        partials: SummaryPartial[]
    }
}

export const initGameModuleState: GameModuleState = {
    isLoading: false,
    steps: [],
    summary: {
        order: [],
        partials: []
    }
}

export enum GAME_ACTION {
    FETCH_CONFIG_SUCCESS = 'game:fetch-config-success'
}

export type GameModuleAction = {
    type: GAME_ACTION,
    payload: any
}
