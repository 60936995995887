import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { USER_ROLE } from "../../modules/user";
import useUser from "../../modules/user/hooks/use-user";
import { getGameRoute, getTeacherPanelRoute, getUserSummaryRoute } from "../../routes/routes";
import ErrorInfo from "../error";
import LogoutButton from "../logout-button";

import './style.scss';

type Props = {

}

const Welcome: React.FC<Props> = () => {

    const { user, error: userError } = useUser();

    const firstRunExists = Boolean(user.firstRun);
    const lastRunExists = Boolean(user.lastRun);
    const activeRunExists = Boolean(user.activeRun);

    const isSummaryAvaiable = lastRunExists || firstRunExists;

    const showTeacherMenu = user.role === USER_ROLE.TEACHER;
    const pageHeader = useRef<HTMLDivElement>(null);

    const error = userError;

    useEffect(() => {
        pageHeader.current?.focus();
    }, [])


    return <main className="welcome-page">
        {error && <ErrorInfo />}
        {!error && <>
            <div className="top-gfx"></div>
            <div className="content-wrapper">
                <h1 className="heading-1" tabIndex={-1} ref={pageHeader}>Cześć!</h1>
                <div className="welcome-text">
                    <p>Za chwilę weźmiesz udział w grze, której przebieg będzie zależał od wyborów, których dokonasz. Odczytaj pytanie, zastanów się, jak zachował(a)byś się w danej sytuacji i wybierz odpowiedź najbliższą Tobie. Po każdej decyzji poznasz jej konsekwencje, otrzymasz informację zwrotną i punkty w trzech kategoriach. Na koniec gry Twoje punkty i otrzymane informacje zwrotne zostaną zsumowane, poznasz zasady i porady, które pomogą Ci sprawniej i uważniej współpracować online. Powodzenia!</p>
                </div>
            </div>

            <nav>
                <div className="left">
                    <Link className="button" to={getGameRoute()}>{activeRunExists ? 'Kontynuuj grę' : 'Zacznij grę'}</Link>
                </div>
                <div className="right">
                    {isSummaryAvaiable && <Link className="button" to={getUserSummaryRoute()}>Zobacz podsumowanie</Link>}
                    {!isSummaryAvaiable && <a className="button disabled" aria-disabled="true" href="/">Zobacz podsumowanie</a>}
                </div>
            </nav>

            <div className="logout">
                <LogoutButton />
            </div>

            {showTeacherMenu && <div className="teacher-menu">
                <h2>Dla administratora</h2>
                <div className="links">
                    <Link className="button" to={getTeacherPanelRoute()}>Panel administratora</Link>
                </div>
            </div>}
        </>}

    </main>
}

export default Welcome;
