import { MainModuleState, MainModuleAction, MAIN_MODULE_ACTION } from ".";

export default function UserReducer(state: MainModuleState, action: MainModuleAction): MainModuleState {
    const { type, payload } = action;
    switch (type) {
        case MAIN_MODULE_ACTION.FETCH_CONFIG:
            return {
                ...state,
                isLoading: true
            }
        case MAIN_MODULE_ACTION.FETCH_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }

        case MAIN_MODULE_ACTION.SET_HIGH_CONTRAST_MODE:
            return {
                ...state,
                isHighContrastMode: payload.value
            }

        case MAIN_MODULE_ACTION.SET_FONT_SIZE:
            return {
                ...state,
                fontSize: payload.value
            }
    }

    return state;
}
