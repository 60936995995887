import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ClassListPage from './routes/teacher/class-list';
import GamePage from './routes/game';

import HomePage from './routes/home';
import { getClassListRoute, getClassPanelRoute, getClassRaportRoute, getGameEndRoute, getGameRoute, getHomeRoute, getRulesListRoute, getSummaryRoute, getTeacherPanelRoute, getUserSummaryRoute } from './routes/routes';
import RulesListPage from './routes/teacher/rules-list';
import SummaryPage from './routes/summary';
import ClassRaportPage from './routes/teacher/class-raport';
import PageFooter from './components/page-footer';
import AccessibilityBar from './components/accesibility-bar';
import UserSummaryPage from './routes/user-summary';
import { CONFIG } from './config';
import HostingContainer from './modules/hosting/container';
import TestStep from './routes/test-step';
import TeacherPanelPage from './routes/teacher/teacher-panel';
import ClassPanelPage from './routes/teacher/class-panel/class-panel';
import GameEndPage from './routes/game-end';

export default function App() {

    return <>
        <AccessibilityBar />
        <div className="page-container">
            <AzureOrHosting>
                <BrowserRouter>
                    <Switch>
                        <Route path={getHomeRoute()} component={HomePage} exact />
                        <Route path={getGameRoute()} component={GamePage} exact />
                        <Route path={getGameEndRoute()} component={GameEndPage} exact />
                        <Route path={getSummaryRoute()} component={SummaryPage} exact />
                        <Route path={getClassListRoute()} component={ClassListPage} exact />
                        <Route path={getRulesListRoute()} component={RulesListPage} exact />
                        <Route path={getClassRaportRoute()} component={ClassRaportPage} exact />
                        <Route path={getUserSummaryRoute()} component={UserSummaryPage} exact />

                        <Route path={getTeacherPanelRoute()} component={TeacherPanelPage} exact></Route>
                        <Route path={getClassPanelRoute()} component={ClassPanelPage} exact></Route>

                        <Route path={'/step/:id'} component={TestStep}></Route>

                        <Route component={HomePage} />
                    </Switch>
                </BrowserRouter>
            </AzureOrHosting>
        </div>
        <PageFooter />
    </>
}

const AzureOrHosting: React.FC<{}> = ({children}) => {

    return <>
        <HostingContainer>{children}</HostingContainer>
    </>
}



