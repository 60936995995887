import { useCallback, useEffect } from "react";
import { MAIN_MODULE_ACTION, useMainModuleDispatch, useMainModuleState } from ".."

export default function useHighContrastMode() {

    const {isHighContrastMode} = useMainModuleState();
    const dispatch = useMainModuleDispatch();

    const setMode = useCallback((value: boolean) => {
        dispatch({
            type: MAIN_MODULE_ACTION.SET_HIGH_CONTRAST_MODE,
            payload: {
                value
            }
        })
    }, [dispatch])

    useEffect(() => {
        const body = document.body;

        if (isHighContrastMode) {
            body.classList.add('high-contrast-mode')
        } else {
            body.classList.remove('high-contrast-mode')
        }

        return () => body.classList.remove('high-contrast-mode');

    }, [isHighContrastMode])

    return {
        setMode,
        isEnabled: isHighContrastMode
    }
}
