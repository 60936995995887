import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import Loader from "../../components/loader";
import { useFinishRunRequest } from "../../modules/network";
import useUser from "../../modules/user/hooks/use-user";
import { getSummaryRoute } from "../routes";
import './style.scss';

export default function GameEndPage() {
    const {user} = useUser();
    const finishRunRequest = useFinishRunRequest();
    const queryClient = useQueryClient();
    const history = useHistory();

    const { mutateAsync: finishRun } = useMutation(finishRunRequest, {
        onSuccess(data) {
            const runId = user.activeRun;
            if (user.id) {
                queryClient.setQueryData(['get-user', user.id], data);
            }

            history.replace(getSummaryRoute(runId));
        }
    });

    useEffect(() => {
        if (user.activeRun) {
            finishRun({runID: user.activeRun});
        }
    }, [finishRun, user])

    return <Loader />
}
