import { useMemo } from "react";
import { useQuery } from "react-query"
import { GameModuleState, initGameModuleState } from "..";
import Network from "../../network"

export default function useGame() {

    const { data, isFetching, error } = useQuery('get-game', Network.getGame, {
        staleTime: Infinity
    });

    const game = useMemo<GameModuleState>(() => {
        return data || initGameModuleState;
    }, [data]);

    return {
        isLoading: isFetching,
        game,
        error
    }
}
