import { useMemo } from "react";
import useGame from "../../modules/game/hooks/use-game";
import useGetActionStepSumary from "../../modules/game/hooks/use-get-element-sumary";

type Props = {
    elementId: string
    gameRun: {
        choices: { key: string, value: number }[],
        rules: string[]
    }
}

const SummaryElement: React.FC<Props> = ({ elementId, gameRun }) => {

    const { game } = useGame();

    const userAnswer = useMemo(() => {
        return gameRun.choices.find(c => c.key === elementId)?.value;
    }, [elementId, gameRun])

    const timeInfo = useMemo(() => {
        return game.steps.find(s => s.id === elementId)?.inGameTime;
    }, [game, elementId])

    const summary = useGetActionStepSumary();

    return <>
        {userAnswer && summary && <div className="choise">
            <h3>{timeInfo}</h3>
            <p>{summary(elementId, userAnswer).text}</p>
        </div>
        }
    </>
}

export default SummaryElement;
