import { GameRunModuleAction, GameRunModuleState, GAME_RUN_ACTION, initGameRunState } from ".";

export default function UserReducer(state: GameRunModuleState, action: GameRunModuleAction): GameRunModuleState {

    const { type, payload } = action;

    switch (type) {
        case GAME_RUN_ACTION.SET_CHOICE:
            return {
                ...state,
                answers: {
                    ...state.answers,
                    choices: [
                        ...state.answers.choices.filter(c => c.key !== payload.stepId),
                        {
                            key: payload.stepId,
                            value: payload.value
                        }
                    ]
                }
            }


        case GAME_RUN_ACTION.SET_RULES:
            return {
                ...state,
                answers: {
                    ...state.answers,
                    rules: payload.rules
                }
            }

        case GAME_RUN_ACTION.SET_ID:
            return {
                ...initGameRunState,
                id: payload.id
            }

        case GAME_RUN_ACTION.FETCH_SUCCESS:
            return {
                ...state,
                answers: {
                    ...payload
                }
            }

        case GAME_RUN_ACTION.RESET:
            return {
                ...initGameRunState
            }
    }

    return state;
}
